import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layoutX/index.vue'
import DocLayout from '@/layoutX/docs.vue'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect:'/p/index'
    },
    {
      path: '/p',
      component: Layout,
      children: [{
        path: 'index',
        component: () => import('@/views/pIndex/index'),
        name: 'pIndex',
      }]
    },
    {
      path: '/message',
      component: Layout,
      children: [{
        path: 'index',
        component: () => import('@/views/message/index'),
        name: 'message',
      }]
    },
    {
      path: '/document',
      component: DocLayout,
      children: [
        {
          //开发指南
          path: 'index',
          component: () => import('@/views/document/index'),
          name: 'document',
        },{
          //快速上手
          path: 'start',
          component: () => import('@/views/document/start'),
          name: 'document',
        }
      ]
    }
  ]
})