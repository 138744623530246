<template>
    <div>
        <div class="loadingIndex" v-if="loading">
            <img src="@/assets/img/logo.jpg" alt="">
        </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-white" style="border-bottom: 1px solid #eee;">
            <div class="container">
                <!-- Brand -->
                <a class="navbar-brand" href="${request.contextPath}/p/index">
                    <img src="@/assets/img/logo3.png" alt="" style="width:6.6rem;height:4rem">
                </a>
                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav mt-4 mt-lg-0 ml-auto">
                        <li class="nav-item ">
                            <a class="nav-link" href="#/p/index">首页</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="#/message/index">消息市场</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="#/document/index">开发文档</a>
                        </li>
                        <!-- <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/config">Token&Test</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/topic">主题管理</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/push-log">推送日志</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/ext">消息市场</a>
                        </li>
                        <li class="nav-item dropdown dropdown-animate" data-toggle="hover">
                            <a class="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">文档</a>
                            <div class="dropdown-menu dropdown-menu-single">
                                <a href="index.html" class="dropdown-item">Homepage</a>
                                <a href="about.html" class="dropdown-item">About us</a>
                                <a href="contact.html" class="dropdown-item">Contact</a>
                                <div class="dropdown-divider"></div>
                                <a href="login.html" class="dropdown-item">Login</a>
                            </div>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/ext">GitHub</a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" href="${request.contextPath}/p/ext">开发者后台</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
        <router-view />
        <footer class="position-relative" id="footer-main">
            <div class="footer pt-lg-7 footer-dark bg-dark">
                <!-- SVG shape -->
                <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
                    <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px"
                        viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100;" xml:space="preserve"
                        class="">
                        <polygon points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>
                <!-- Footer -->
                <div class="container pt-4">
                    <div class="row">
                        <div class="col-lg-4 mb-5 mb-lg-0">
                            <!-- Theme's logo -->
                            <a href="index.html">
                                <img src="@/assets/img/logo.png" alt="" style="width:12rem">
                            </a>
                            <!-- Webpixels' mission -->
                            <p class="mt-4 text-sm opacity-8 pr-lg-4">
                                「咻推」，英文名称「xPush」，专注于为个人和企业用户提供安全稳定、全域通道的消息通知解决方案，致力于实现更精准实时的触达、更低的消息运营成本、更智能的消息处理能力、更好的产品服务体验
                            </p>
                        </div>
                        <div style="margin: 0 auto;" :class="pcFlag ? 'pcWx' : 'h5Wx'">
                            <div class="wx-box">
                                <img src="@/assets/img/wx.jpg" alt="" style="width:8rem">
                                <div style="font-size: 1rem;margin-top:0.875rem">官方微信群聊二维码</div>
                            </div>
                            <div class="wx-box">
                                <img src="@/assets/img/wx.jpg" alt="" style="width:8rem">
                                <div style="font-size: 1rem;margin-top:0.875rem">官方运营微信二维码</div>
                            </div>
                        </div>
                    </div>
                    <hr class="divider divider-fade divider-dark my-4">
                    <div class="row align-items-center justify-content-md-between pb-4">
                        <div class="col-md-6">
                            <div class="copyright text-sm font-weight-bold text-center text-md-left">
                                出品公司：芯源易码（杭州）信息技术有限公司
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="copyright text-sm font-weight-bold text-center text-md-left">
                                <a href="https://beian.miit.gov.cn/">浙ICP备2020044678号-2</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="linkArea">
                    <a href="">友情链接1</a>
                    <a href="">友情链接1</a>
                    <a href="">友情链接1</a>
                    <a href="">友情链接1</a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'layoutX',
    data() {
        return {
            pcFlag: true,
            loading: false
        }
    },
    mounted() {
        //this.disableScroll()
        if (document.getElementsByTagName('body')[0].offsetWidth < 1000) {
            this.pcFlag = false
        }
        window.addEventListener("load", function () {
            setTimeout(function () {
                document.querySelector('body').classList.add('loaded');
            }, 300);
        });
        // setTimeout(() => {
        //     this.enableScroll()
        //     this.loading = false
        // }, 1000)
    },
    methods: {
        disableScroll() {
            document.body.style.position = 'fixed';
            document.body.style.top = '0';
            document.body.style.left = '0';
            document.body.style.width = '100%';
        },
        enableScroll() {
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.width = '';
        }
    }
}
</script>
<style>
@keyframes hidePreloader {
    0% {
        width: 100%;
        height: 100%;
    }

    100% {
        width: 0;
        height: 0;
    }
}

body>div.preloader {
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 1071;
    opacity: 0;
    transition: opacity .5s ease;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

body:not(.loaded)>div.preloader {
    opacity: 1;
}

body:not(.loaded) {
    overflow: hidden;
}

body.loaded>div.preloader {
    animation: hidePreloader .5s linear .5s forwards;
}

.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
}

.toast {
    max-width: 300px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.custom-table th,
.custom-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    /* 防止内容换行 */
    overflow: hidden;
    /* 隐藏溢出的内容 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}

.custom-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f3f3f3;
}

.custom-table tbody tr:hover {
    background-color: #e6e6e6;
}

.custom-table i {
    cursor: pointer;
}

.custom-table i.fa-check-circle::before {
    content: "\f058";
    /* 对勾图标 */
}

.custom-table i.fa-exclamation-circle::before {
    content: "\f06a";
    /* 错误图标 */
}

.custom-table i.fa-sync-alt::before {
    content: "\f021";
    /* 发送中图标 */
}

.custom-table i[title] {
    position: relative;
    display: inline-block;
}

@media (max-width: 768px) {

    .custom-table th,
    .custom-table td {
        max-width: 100px;
        /* 设置最大宽度 */
    }
}
</style>
<style lang="scss" scoped>
.pcWx {
    display: flex;
    flex: 1;
    justify-content: center;

    .wx-box {
        text-align: center;

        &:nth-child(1) {
            margin-right: 5rem;
        }
    }
}

.h5Wx {
    .wx-box {
        text-align: center;

        &:nth-child(1) {
            margin-bottom: 1rem;
        }
    }
}

.loadingIndex {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 102;

    img {
        width: 5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.linkArea{
    display: flex;
    padding-bottom:1rem;
    max-width: 60rem;
    a{
        width:20%;
        text-align: center;
        font-size: 0.8rem;
        color:#888;
    }
}
</style>