<template>
  <div>
    <!-- Nav -->
    <header class="header" id="header-main">
      <!-- Main navbar -->
      <nav class="navbar navbar-main navbar-expand-lg fixed-top navbar-shadow navbar-light bg-white border-bottom"
        id="navbar-main">
        <div class="container-fluid justify-content-between">
          <!-- User's navbar -->
          <div class="navbar-user d-lg-none">
            <ul class="navbar-nav flex-row align-items-center">
              <li class="nav-item" @click.prevent="handleFold">
                <a href="#" class="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin"
                  data-target="#sidenav-main"><i data-feather="menu"></i></a>
              </li>
            </ul>
          </div>
          <!-- Navbar brand -->
          <a class="navbar-brand" href="#/document/index">
            <img alt="Image placeholder" src="../assets/img/logo.jpg" id="navbar-logo">
            <sup class="text-muted text-xs text-uppercase">Docs</sup>
          </a>
          <!-- Live preview -->
          <ul class="navbar-nav flex-row align-items-center d-lg-none">
            <li class="nav-item">
              <a href="#/p/index" class="nav-link nav-link-icon"><i data-feather="eye"></i></a>
            </li>
          </ul>
          <!-- Navbar nav -->
          <div class="collapse navbar-collapse" id="navbar-main-collapse">
            <!-- Right menu -->
            <ul class="navbar-nav align-items-center mx-auto">
              <li class="nav-item">
                <a class="nav-link" href="../docs/getting-started/quick-start.html">开始</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="../docs/components/alerts.html">组件</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://github.com/webpixels/quick-website-ui-kit-demo/issues"
                  target="_blank">支持</a>
              </li>
            </ul>
          </div>
          <a href="#/p/index" class="btn btn-sm btn-primary ml-4 btn-icon d-none d-lg-inline-flex">
            <span class="btn-inner--icon"><i data-feather="chevron-left"></i></span>
            <span class="btn-inner--text">返回首页</span>
          </a>
        </div>
      </nav>
    </header>
    <router-view />

    <!-- 手机端目录 -->
    <!-- Navigation -->
    <div class="docs-sidebar pt-6 pt-lg-7 sider-bar" v-if="!pcFlag">
      <h6 class="mt-4">Getting started</h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a href="../docs/getting-started/quick-start.html" class="nav-link">Quick start</a>
        </li>
        <li class="nav-item">
          <a href="../docs/getting-started/build-tools.html" class="nav-link">Build tools</a>
        </li>
      </ul>
      <h6 class="mt-4">Styleguide</h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a href="../docs/styleguide/colors.html" class="nav-link">Colors</a>
        </li>
        <li class="nav-item">
          <a href="../docs/styleguide/typography.html" class="nav-link">Typography</a>
        </li>
        <li class="nav-item">
          <a href="../docs/styleguide/icons.html" class="nav-link">Icons</a>
        </li>
      </ul>
      <h6 class="mt-4">Components</h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a href="../docs/components/alerts.html" class="nav-link">Alerts</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/avatar.html" class="nav-link">Avatar</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/badge.html" class="nav-link">Badge</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/breadcrumb.html" class="nav-link">Breadcrumb</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/buttons.html" class="nav-link">Buttons</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/card.html" class="nav-link">Card</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/collapse.html" class="nav-link">Collapse</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/dropdowns.html" class="nav-link">Dropdowns</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/forms.html" class="nav-link">Forms</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/input-group.html" class="nav-link">Input group</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/modal.html" class="nav-link">Modal</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/navs.html" class="nav-link">Navs</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/navbar.html" class="nav-link">Navbar</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/pagination.html" class="nav-link">Pagination</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/popovers.html" class="nav-link">Popovers</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/progress.html" class="nav-link">Progress</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/spinners.html" class="nav-link">Spinners</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/tables.html" class="nav-link">Tables</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/toasts.html" class="nav-link">Toasts</a>
        </li>
        <li class="nav-item">
          <a href="../docs/components/tooltips.html" class="nav-link">Tooltips</a>
        </li>
      </ul>
      <h6 class="mt-4">Plugins</h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a href="../docs/plugins/animate.html" class="nav-link">Animate</a>
        </li>
        <li class="nav-item">
          <a href="../docs/plugins/google-maps.html" class="nav-link">Google maps</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocLayout',
  data() {
    return {
      pcFlag: true,
      fold: false,
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].setAttribute('class', 'docs')
    this.$feather.replace({
      'width': '1em',
      'height': '1em'
    })
    if (document.getElementsByTagName('body')[0].offsetWidth < 900) {
      this.pcFlag = false
      this.$nextTick(()=>{
        this.handleFold()
      })
    }
  },
  methods:{
    handleFold(){
      this.fold = !this.fold
      if(this.fold){
        document.getElementsByClassName('sider-bar')[0].style.transform = 'translate(-100%,0)'
      }else{
        document.getElementsByClassName('sider-bar')[0].style.transform = 'translate(0,0)'
      }
    }
  }
}
</script>

<style>
@keyframes hidePreloader {
  0% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 0;
    height: 0;
  }
}

body>div.preloader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 1071;
  opacity: 0;
  transition: opacity .5s ease;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

body:not(.loaded)>div.preloader {
  opacity: 1;
}

body:not(.loaded) {
  overflow: hidden;
}

body.loaded>div.preloader {
  animation: hidePreloader .5s linear .5s forwards;
}

.sider-bar{
  position: absolute;
  top:0;
  left:0;
  height:100%;
  overflow-y: scroll;
  background: #fff;
  padding:1rem;
  transition: transform 0.2s linear;
  border-right: 1px solid #eee;
}
</style>