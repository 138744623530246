var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loadingIndex"},[_c('img',{attrs:{"src":require("@/assets/img/logo.jpg"),"alt":""}})]):_vm._e(),_vm._m(0),_c('router-view'),_c('footer',{staticClass:"position-relative",attrs:{"id":"footer-main"}},[_c('div',{staticClass:"footer pt-lg-7 footer-dark bg-dark"},[_c('div',{staticClass:"shape-container shape-line shape-position-top shape-orientation-inverse"},[_c('svg',{staticStyle:{"enable-background":"new 0 0 2560 100"},attrs:{"width":"2560px","height":"100px","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","preserveAspectRatio":"none","x":"0px","y":"0px","viewBox":"0 0 2560 100","xml:space":"preserve"}},[_c('polygon',{attrs:{"points":"2560 0 2560 100 0 100"}})])]),_c('div',{staticClass:"container pt-4"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{class:_vm.pcFlag ? 'pcWx' : 'h5Wx',staticStyle:{"margin":"0 auto"}},[_vm._m(2),_vm._m(3)])]),_c('hr',{staticClass:"divider divider-fade divider-dark my-4"}),_vm._m(4)]),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-white",staticStyle:{"border-bottom":"1px solid #eee"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"${request.contextPath}/p/index"}},[_c('img',{staticStyle:{"width":"6.6rem","height":"4rem"},attrs:{"src":require("@/assets/img/logo3.png"),"alt":""}})]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarCollapse","aria-controls":"navbarCollapse","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarCollapse"}},[_c('ul',{staticClass:"navbar-nav mt-4 mt-lg-0 ml-auto"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#/p/index"}},[_vm._v("首页")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#/message/index"}},[_vm._v("消息市场")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#/document/index"}},[_vm._v("开发文档")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 mb-5 mb-lg-0"},[_c('a',{attrs:{"href":"index.html"}},[_c('img',{staticStyle:{"width":"12rem"},attrs:{"src":require("@/assets/img/logo.png"),"alt":""}})]),_c('p',{staticClass:"mt-4 text-sm opacity-8 pr-lg-4"},[_vm._v(" 「咻推」，英文名称「xPush」，专注于为个人和企业用户提供安全稳定、全域通道的消息通知解决方案，致力于实现更精准实时的触达、更低的消息运营成本、更智能的消息处理能力、更好的产品服务体验 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wx-box"},[_c('img',{staticStyle:{"width":"8rem"},attrs:{"src":require("@/assets/img/wx.jpg"),"alt":""}}),_c('div',{staticStyle:{"font-size":"1rem","margin-top":"0.875rem"}},[_vm._v("官方微信群聊二维码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wx-box"},[_c('img',{staticStyle:{"width":"8rem"},attrs:{"src":require("@/assets/img/wx.jpg"),"alt":""}}),_c('div',{staticStyle:{"font-size":"1rem","margin-top":"0.875rem"}},[_vm._v("官方运营微信二维码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-md-between pb-4"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"copyright text-sm font-weight-bold text-center text-md-left"},[_vm._v(" 出品公司：芯源易码（杭州）信息技术有限公司 ")])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"copyright text-sm font-weight-bold text-center text-md-left"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("浙ICP备2020044678号-2")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkArea"},[_c('a',{attrs:{"href":""}},[_vm._v("友情链接1")]),_c('a',{attrs:{"href":""}},[_vm._v("友情链接1")]),_c('a',{attrs:{"href":""}},[_vm._v("友情链接1")]),_c('a',{attrs:{"href":""}},[_vm._v("友情链接1")])])
}]

export { render, staticRenderFns }